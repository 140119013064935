table.werkliste {

border:none;
border-collapse: collapse;
}

.werkliste  > tbody > tr > td, .werkliste  > thead > tr > th{
    border-top: 0;
    border-bottom:1px solid #000;
    border-right:10px solid transparent;
    padding: 8px 0;
}


.werkliste  > tbody > tr > td:last-child, .werkliste  > thead > tr > th:last-child{
  border-right: none;
}
.werkliste  > tbody > tr > td{
   color: #000;
}
.werkliste  > tbody > .clickable > td{
   color: #000;
}

.table-hover > tbody > tr:hover{
  background:transparent;
  //background:#000;
  & > td {
    //background:#000;
    //color: #fff;
    //border:none !important;
    //font-weight: bold;
  }
}


table.tablesorter thead tr .header , th.tablesorter-header {
    //background-image: url(../img/small.gif);
    cursor: pointer;
    //font-weight: bold;
    background-repeat: no-repeat;
    background-position: center right;
    //padding-left: 20px;
    //border-right: 1px solid #dad9c7;
    //margin-left: -1px;
}
table.tablesorter thead tr .headerSortDown, th.tablesorter-headerAsc {
    background-image: url(../images/icon_down.svg);
}
table.tablesorter thead tr .headerSortUp, th.tablesorter-headerDesc {
    background-image: url(../images/icon_up.svg);
}