.navbar-default {
    //background-color: #fff;
    //border-color: #000;
    border:none ;

    background-color: transparent;
    //background-color: rgba(0, 0, 0, 0.1);

    margin-bottom: 3em;

    & .navbar-collapse{
        border:none ;
        box-shadow:none;
    }
}
.navbar-default .navbar-brand {
    color: #000;
    font-size: 1em;
    //font-weight: bold;
    //font-family: "freight-text-pro", serif;
    letter-spacing: 0.2px;
}
.navbar-default .navbar-nav > li > a{
    color: #000;
    padding:  15px 10px 15px 15px;
}
.home .navbar-default .navbar-nav > li > a{
    color: #fff;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
    /*background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);*/
    background:transparent;
    color: #000;
    font-weight: bold;
}


.qtranxs-lang-menu .glyphicon{
    display: none;
}



/* hamburger */
.navbar-default {
    & .navbar-toggle,  .navbar-toggle:hover, .navbar-toggle:focus{
      background:transparent;
      border:none;
          margin-right: 0;
        margin-top: 0;
        z-index: 1032;
        //position: fixed;
        //top: 5px;
        //right: 5px;
        padding-right: 15px;
        padding-top: 15px;

    }
     & .navbar-toggle .icon-bar {
        -webkit-border-radius:0;
        border-radius:0;

        display: block;
        height: 2px;
        width: 25px;
        background-color: #000;
    }
    & .navbar-toggle .icon-bar + .icon-bar {
        margin-top: 5px;
    }

    & .navbar-toggle.in .icon-bar{
      background-color: #fff;

    }
}
.home .navbar-default .navbar-toggle .icon-bar{
          background-color: #fff;
}


/* hamburger */

/*
.navbar-default {
    & .navbar-toggle,  .navbar-toggle:hover, .navbar-toggle:focus{
        float: none;
        width: 35px;
        height: 35px;
        -webkit-border-radius: 35px;
        border-radius: 35px;
        background:lime;
      border:none;
          margin-right: 15px;
        margin-top: 15px;
        margin:15px;
        z-index: 1032;
        //position: fixed;
        //top: 5px;
        //right: 5px;
        padding: 0px;

    }
    & .navbar-toggle.in  {
      background-color: #000;

    }
}


.navbar-collapse{
    margin-top: 8px;
}
*/

.page-header {
    border-bottom: none;
    margin: 0;//40px 0 20px;
    padding-bottom:0;// 9px;
}

hr{
    border-color: #000;
     margin-bottom: 1em;
    margin-top: 1em;
}
