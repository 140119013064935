body#tinymce {

	background: #fff !important;
  margin: 12px !important;
  padding: 0 !important;
	  h3 {
	    font-size: 1em;
	}
	p, p::after, p::before {
   	 	box-sizing: inherit;
	}
}
 