
.bigimg{
	margin-bottom: 2em;
}

.entry-content {
	margin-bottom:2em;
	& a {
		border-bottom:1px solid;
		/*&:before{
		 	background: rgba(0, 0, 0, 0) url("../images/icon_right.svg") no-repeat scroll -5px center / 15px auto;
		    content: "";
		    display: inline-block;
		    height: 10px;
		    width: 8px;
		   }

		&:hover{
			//border:none;
			color: #fff;
			background: #000;
		}*/
		&:hover{
			border:none;
			//color: #fff;
			//background: #000;
		}
	}
}


.lauftext{
			  font-family: "freight-text-pro", "proxima-nova", Georgia, serif;

	font-size: 1.4em;
	margin-bottom:3em;
	letter-spacing: 0px;
	-webkit-hyphens:auto;
	-moz-hyphens:auto;
	-ms-hyphens:auto;
	hyphens:auto;

}

.details{
	border-top:1px solid;

	//margin-left: 2em;
	padding-top:0.5em;
	padding-top:0.6em;

	font-size: 90%;
    letter-spacing: 0.5px;

    margin-top: 0.6em;

}
.legende{
		font-size: 90%;
    letter-spacing: 0.5px;
}

.page-header h1, .single .entry-title {
    //border-bottom: 1px solid;
    display: inline-block;
    font-size: 2em;
    //margin-bottom: 1em;
    margin-top: 0;
    //margin-left: 2em;
    //text-align: center;
    font-size: 3em;
    font-weight: bold;
    letter-spacing: -0.5px;
}


blockquote {
    border-left:  none;
    font-family: inherit, georgia, serif;
    font-size: inherit;
    font-style: italic;
    margin: 0;
    padding: 0;
}

.bildergalerie{
	//border-left:1px solid;

	padding-bottom: 2em;
	padding-top:0.6em;
}

article footer{
	height:50px;
	//border-top:1px solid;
	text-align: center;

}