body{
	//padding-top:60px;
	  //font-family: Helvetica "freight-sans-pro", "proxima-nova", sans-serif;
	  font-family: Helvetica, Arial, sans-serif;
	letter-spacing: 1px;
	  letter-spacing: 0.6px;
	  color: #000;

	 -webkit-font-smoothing:antialiased;
  	-moz-osx-font-smoothing:grayscale;

  	//background: #fefef1;
  	 //transition: 1.5s cubic-bezier(0.600, 0.040, 0.980, 0.335);
     -webkit-transition: background-color 2.5s ease-in-out;
     transition: background-color 2.5s ease-in-out;


        background: $dunkel;

}
/*
.no-js body{
    background: #fefef1;
    transition: none;
}

.js body{



}*/

.home , .home .navbar-default {
	//background: $dunkel;
	color: #fff;
	& a {
		color: #fff;
	}
}
.home .navbar-default {
    background: transparent;
}

.single{
		//background: $dunkel;
}
.home .wrap.container{
	width: 100%;
}


a, a:hover, a:focus, a:visited{
	color:#000;
	text-decoration: none;
	outline:none;
}



/*
body.logged-in {
	padding-top:92px;
	& .navbar-fixed-top {
	    border-width: 0 0 1px;
	    top: 32px;
	}
}
*/
/*
header .container, .home .container{
	width:100%;
}
*/
.griditem, .grid-sizer{
	width: 90%;
	width:calc(100% - 30px);
	float: left;

}
.itemtitle{
	/*position: absolute;
	top:0;
	left:0;
	width:100%;
	padding:5px;
	display:none;
	font-size: 2em;
	color:#fff;*/


	  color: #ffffff;
    display: none;
    font-size: 2em;
    font-weight: bold;
    left: -7px;
    letter-spacing: -0.5px;
    line-height: 1em;
    padding: 5px;
    position: absolute;
    top: -5px;
    width: 100%;
    z-index: 999;

    -webkit-hyphens:auto;
    hyphens: auto;
}
.grid-link:hover{
	& img{
		opacity: 0.6;
	}
	& .itemtitle{
		display: block;
	}
}

.filter {
    margin-bottom: 2em;
    //border-bottom: 1px solid;
    //padding-bottom: 2em;

}

.filterbtn{
	cursor:pointer;
	color:black;
	padding: 2px 2px 1px;
	&.active,&:hover{
		background:#000;
		color: #fff;
	}
}


.toplink {
    background: url("../images/icon_up.svg") no-repeat 0 0;
    display: inline-block;
    width: 30px;
    height: 30px;
}
/*.rightlink:after{
	content: '';
	background: url("../images/icon_right.svg") no-repeat 0 0;
    display: inline-block;
    width: 30px;
    height: 30px;
}
.leftlink:before{
	content: '';
	background: url("../images/icon_left.svg") no-repeat 0 0;
    display: inline-block;
    width: 30px;
    height: 30px;
}*/
.leftlink {
    background: url("../images/icon_left.svg") no-repeat scroll -10px 0;
    display: inline-block;
    height: 30px;
    padding-left: 20px;
    padding-top: 6px;
}
.rightlink {
    background: url("../images/icon_right.svg") no-repeat scroll right 0;
    display: inline-block;
    height: 30px;

    padding-top: 6px;
    margin-right: -10px;
    padding-right: 30px;
}




.navbar-brand {
    float: none;
     padding: 0;
    font-size: 18px;
    line-height: 20px;
     height: auto;
    /* text-align: left; */
    display: block;
}



@media (min-width: $screen-sm-min) {

    .navbar-brand {
       float: right;
        padding: 15px;
    }

	.navbar-nav{
		float: right;
	}

	.griditem, .grid-sizer{
		width: 47%;
		width:calc(50% - 20px);
	}

	.filter {
	    //margin-bottom: 3em;

	}
}


@media (min-width: $screen-md-min) {

	.griditem, .grid-sizer{
		//margin: 2em;
		//width: 20%;
		margin: 30px;
		width:calc(33.33333% - 80px);

		&.medium{
			margin: 20px;
			//width: 30%;
			width:calc(33.33333% - 60px);

		}
		&.large{
			margin: 0;
			width: 50%;
			width:calc(50% - 20px);

		}
	}
}


@media (min-width: $screen-lg-min) {

		.griditem, .grid-sizer{
		//margin: 2em;
		//width: 20%;
		margin: 30px;
		width:calc(25% - 75px);

		&.medium{
			margin: 20px;
			//width: 30%;
			width:calc(33.33333% - 60px);

		}
		&.large{
			margin: 0;
			width: 50%;
			width:calc(50% - 20px);

		}
	}

}





/* ==========================================================================
   SEARCH
   ========================================================================== */
.screen-reader-text, .search-submit{
    display: none;
}

#searchform{
    border-top: 1px solid $dunkel;
    border-bottom: 1px solid $dunkel;

}
.input-group{
    width: 100%;
       padding: 1em 0;
}

/*
input[type="text"]::-moz-placeholder{

    color: $dunkel;

}
*/

.form-control::-webkit-input-placeholder { color: $dunkel; }
.form-control:-moz-placeholder { color: $dunkel; }
.form-control::-moz-placeholder { color: $dunkel; }
.form-control:-ms-input-placeholder { color: $dunkel; }



.search-field, .form-control {
    /*font: 42px/28px "Fedra Serif Screen Regular 8",serif;*/
    font-size: 23px;
    /*line-height: 28px;*/
    background: transparent;
    border: none;
    cursor: text;
    margin: 0 0 2em 56px;
    padding: 0;
    color: $dunkel;
    /*position: relative;
    -webkit-transition: width 400ms ease, background 400ms ease;
    transition:         width 400ms ease, background 400ms ease;
    width: 0;*/
    width: 100%;
    box-shadow: none;
    margin: 0 0 1em 0px;
    border-radius: 0;

    height: auto;


}
.search-field:focus, .form-control:focus {
    box-shadow: none;
    cursor: text;
    outline: 0;
}

.line{
    border-bottom: 1px solid $dunkel;

}

.search-result, .news .post{
    border-top: 1px solid $dunkel;
     clear: both;
    padding: 15px 0;
    h2{
         margin-bottom: 0.1em;
        margin-top: 0;
        font-size: 25px;
        letter-spacing: 0;
        font-weight: bold;
    }
}
.search-result h1{
    margin-bottom: 0;
    line-height: inherit;
}
.search-result img, .news .post img {
     float: left;
    height: auto;
    padding: 3px 20px 18px 0;
    width: 240px;
}



 

.navbar-nav {
       float: left;
    }

/* alte version: 

    .navbar-header {
    	//float: none;
	}
	
    .navbar-collapse.collapse{
        display: none!important;
        height: 0!important;
        padding-bottom: 0;
        overflow: hidden!important;
    }
    .navbar-toggle.collapsed{
        display:block!important;
    }
    .navbar-toggle{
        display:block!important;
    }
    .navbar-collapse.collapse.in{
        display:block!important;
        height:auto!important;
        overflow:visible!important;
    }
*/


.year{
 white-space: nowrap;
}



@media (min-width: $screen-sm-min) {

    .itemtitle{
        font-size: 4em;
    }
    //.navbar-nav_ 
    .navbar-collapse {
        float: left;
        left: -220px;
        //margin-left: -134px;
        margin-top: -20px;
        position: fixed;
        text-transform: uppercase;
        top: 50%;
        transform: rotate(-90deg);
    }
    .navbar-brand {
        float: left;
        //margin-top: -26px;
        //padding: 15px;
       // position: fixed;
       // right: -139px;
       // text-transform: uppercase;
       // top: 50%;
        //transform: rotate(-90deg);
    }

    .home .wrap.container{
            //margin-right: 30px;
            //margin-left: 30px;
            padding-right: 35px;
            padding-left: 35px;
        }
    .home .row {
        //margin-left: -30px;
        //margin-right: -30px;
    }

}